import './scss/core.scss'

// --- Bootstrap
import {Tooltip as bsTooltip, Modal as bsModal, Offcanvas as bsOffcanvas, Popover as bsPopover} from 'bootstrap';

window.bootstrap = {
    Tooltip : bsTooltip,
    Modal: bsModal,
    Offcanvas: bsOffcanvas,
    Popover: bsPopover
}

// --- Umbrella
import Translator from './translator/Translator';
import Spinner from './ui/Spinner'
import ConfirmModal from './ui/ConfirmModal'
import Toast from './ui/Toast'

const LANG = document.querySelector('html').getAttribute('lang')

window.umbrella = {
    LANG: LANG,
    Translator : new Translator(LANG),
    Spinner : Spinner,
    ConfirmModal : ConfirmModal,
    Toast : Toast
}

// --- JsResponseHandler
import JsResponseHandler from './jsresponse/JsResponseHandler';
import configureHandler from './jsresponse/Configure'

const jsResponseHandler = new JsResponseHandler();
configureHandler(jsResponseHandler);

window.umbrella.jsResponseHandler = jsResponseHandler

// --- Bind some elements
import BindUtils from './utils/BindUtils';
BindUtils.enableAll();